/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "duurzaam/duurzaam-7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "duurzaam/duurzaam-7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(relativePath: { eq: "duurzaam/duurzaam.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal2Image: file(
      relativePath: { eq: "duurzaam/duurzaam-1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal3Image: file(
      relativePath: { eq: "duurzaam/duurzaam-7.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Warmwater met een zonneboiler`,
  textWithLink: () => (
    <>
      <p>
        Wilt u de energienota voor het gasverbruik drastisch verlagen? Kies dan
        voor een zonneboiler. Deze duurzame oplossing maakt gebruik van de zon
        als energiebron en levert een gemiddelde besparing van 50% op. Ook in de
        winter kan een zonnecollector genoeg water verwarmen en indien nodig
        springt de HR-ketel of warmte-unit bij. Zo bent u dus te allen tijde
        verzekerd van heerlijk warm tapwater.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  header: `Subsidie`,
  textWithLink: () => (
    <>
      <p>
        Als u een zonneboiler aanschaft, heeft u recht op subsidie. De hoogte
        van de subsidie wordt bepaald aan de hand van het type zonneboiler.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
      <AniLink fade to='/duurzaam' className='ml-sm-4x'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Zonneboilers = ({ data, path, location }) => {
  const seo = {
    title: `Zonneboilers`,
    description: `Een zonneboilers zorgt voor snel en royaal warmwatercomfort in de keuken en badkamer. U bent bij ons aan het juiste adres voor de levering en installatie van een zonneboilers.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Werking van een zonneboiler',
    inner:
      'De werking van een zonneboiler kan vergeleken worden met een tuinslang die in de zon ligt. Het water in de tuinslang wordt door zonnestraling verwarmd. Zo werkt een zonnecollector ook. De collector is gemaakt van een donker gekleurde plaat (zodat de warmte in de collector blijft) met buizen die er zigzaggend overheen lopen en is afgedekt met een glasplaat van speciaal gehard glas. De temperatuur van de vloeistof die door de buizen stroomt, kan oplopen tot ongeveer 90 graden maar in extreme gevallen nog veel verder.',
  };

  const CardHorizontal2 = {
    textFirst: true,
    noSpace: true,
    img: data.CardHorizontal2Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Wat doet een zonneboiler nog meer?',
    inner: `De collector die in het dak is geplaatst, voert de warme vloeistof naar het voorraadvat. In het voorraadvat loopt deze vloeistof door een warmtewisselaar die de warmte overdraagt op het water in het voorraadvat. Wanneer u de kraan opendraait, stroomt er koud water het voorraadvat in en komt er warm water uit. Wanneer de temperatuur te laag is, wordt het tapwater naverwarmd door de cv-ketel, geiser of warmtepomp.`,
  };

  const CardHorizontal3 = {
    textFirst: false,
    noSpace: true,
    img: data.CardHorizontal3Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Voordelen van een zonnecollector',
    textWithLink: () => (
      <ul>
        <li>een zonneboiler benut gratis zonne-energie</li>
        <li>korte terugverdientijd</li>
        <li>50% besparing</li>
        <li>behoeft weinig onderhoud</li>
        <li>niet gevoelig voor storingen</li>
        <li>kan geïnstalleerd worden naast het bestaande verwarmingssysteem</li>
      </ul>
    ),
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `E S I – Zonneboilers`,
    pitchLine: `Wordt duurzaam met de zonneboilers van E S I - Installatietechniek B.V..`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        CardHorizontal2={CardHorizontal2}
        CardHorizontal3={CardHorizontal3}
        textBottom={textBottom}
      />
    </>
  );
};

Zonneboilers.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Zonneboilers;
